import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const nttCustomTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#08122e',
      },
      secondary: {
        main: '#565a6e',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#d5d6db',
        paper: '#d5d6db',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#ffffff',
        indicator: '#08122e',
        color: '#08122e',
        selectedColor: '#fff',
      },
    },
  }),
  defaultPageTheme: 'home',
  components: {
    BackstagePage: {
      styleOverrides: {
        root: {
          background: '#f5f7f9',
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          width: 'auto',
          boxShadow: 'none',
          borderBottom: `2px solid #f5f7f9`,
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#f5f7f9!important',
        },
        root: {
          backgroundColor: '#f5f7f9!important',
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        tabsWrapper: {
          backgroundColor: '#f5f7f9',
        },
        defaultTab: {
          textDecoration: 'none!important',
          '&:hover': {
            background: '#f5f7f9!important',
          },
        },
      },
    },
    BackstageSelectInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: '#f5f7f9',
        },
      },
    },
    BackstageEmptyState: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f7f9',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#f5f7f9',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#f5f7f9!important',
        },
      },
    },
    BackstageTableHeader: {
      styleOverrides: {
        header: {
          backgroundColor: '#f5f7f9',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
        highlightable: {
          '&:hover': {
            background: 'none!important',
            color: '#08122e!important',
            borderRadius: '0px',
          },
        },
        selected: {
          background: '#08122e',
          borderRadius: '10px',
          margin: '4px 0px 4px 2px',
        },
      },
    },
  },
  pageTheme: {
    home: genPageTheme({ colors: ['#08122e', '#08122e'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#08122e', '#08122e'],
      shape: shapes.wave,
    }),
    tool: genPageTheme({ colors: ['#08122e', '#08122e'], shape: shapes.wave }),
    service: genPageTheme({
      colors: ['#08122e', '#08122e'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#08122e', '#08122e'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#08122e', '#08122e'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#08122e', '#08122e'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#08122e', '#08122e'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#08122e', '#08122e'], shape: shapes.wave }),
  },
});
